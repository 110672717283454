.ourServices {
  height: 142vh;
  background-color: #EDF4F9;

}

.ourServicesContainer {
  top: 35px;
  position: relative;
  font-family: 'Oswald', sans-serif ;
  font-weight: 600;
  font-size: 24px;

}

.GpuInfoContainer {

  background: #FFFFFF;
  border: 10px solid #1B83C3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
  text-align: center;
  height: fit-content;
}

.Info {
  color: #359CDD;
  font-weight: 800;
  font-size: 24px;
}

.gpu1 {
  max-width: 200px;
  transform: rotate(1deg);

}

.gpu2 {
  max-width: 200px;
  transform: rotate(-5deg);

}

.gpu3 {
  max-width: 210px;
  transform: rotate(-3deg);
  margin-left: 190px;

}

.gpuContainer {
  border: 7px solid #1B83C3;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  top: -150px ;

}

.ocInfo {

  background: #FFFFFF;
  border: 10px solid #1B83C3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  font-family: 'Oswald', sans-serif ;
  font-weight: 600;
  font-size: 20px;
  text-align: center;

  margin-bottom: 100px;
}

.infoGpu {
  font-family: 'Oswald', sans-serif ;
  font-weight: 800;
  font-size: 24px;
  color: #359CDD;

}

.ocHart {
  border: 7px solid #1B83C3;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  max-width: 600px;
  margin-top: 50px;
}