.mainpage{
    height:100vh;
    background-color: #8DC1E1 ;
}
.navbar{
    background: #FFFFFF;
    border: 10px solid #1E91D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 99%;
    left: 50%;
    transform: translate(-50%);
height: 140px;
}


.MainIcon{
    width: 102px;
}
.MainLogo{
    width: 100%;
    height: 100%; 
    object-fit: contain;

}
.containerLinks{
    font-family: 'Oswald', sans-serif !important;
    font-weight: 600;
    font-size: 21px;
color: #62b2e4;}

.main{
color:#1e91d9 ;
}
