.firstSection{
position: absolute;
top:30%;
left: 0;
right: 0}
.infoBanner{
    background: #FFFFFF;
    border: 10px solid #1B83C3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Oswald', sans-serif !important;
font-size: 26px;
text-align: center;
color: #0D0D0D;
padding: 45px;
margin-bottom: 10px;
font-weight: 600;


}
.Important{
    color: #359CDD;
    font-weight:800;
font-size: 27px;
line-height: 32px;
text-align: center;
font-style: bold;
}


.gpuHandimg{
    width: 100%;
    height:auto;
    border: 12px solid #1B83C3;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}



       .bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-3000px, 0, 0);
  transform: translate3d(-3000px, 0, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
  }
  75% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  }
  90% {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
  }
  100% {
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-3000px, 0, 0);
  transform: translate3d(-3000px, 0, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
  }
  75% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  }
  90% {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
  }
  100% {
  -webkit-transform: none;
  transform: none;
  }
  } 
  .bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    } 