.contact{
    height: 40vh;
    background-color:#c6e0f0 ;
    overflow: hidden
}
.Map{
    height: 40vh;
    width: 40vw;
}

.contactContainer{

    
  background: #FFFFFF;
  border: 10px solid #1B83C3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  font-family: 'Oswald', sans-serif ;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-top: 150px ;
  position: relative !important;
}